import { defineMessages } from 'react-intl'

export const SelectProductMainContentMessages = defineMessages({
  advancedProductTag: {
    defaultMessage: 'ADVANCED',
    id: 'Q1V76JWC',
    description: 'The advanced product tag.',
  },
  apiEndpointsProductButtonLabel: {
    defaultMessage: 'Select API Endpoints',
    id: '7PIO3aUP',
    description: 'The button label for the select api endpoints product button.',
  },
  apiEndpointsProductDescription: {
    defaultMessage:
      'With these easy to use APIs for Inference, you don’t have to worry about creating or maintaining any deployments. Salad hosts the models and you pay only for what you use.',
    id: '62wxByDC',
    description: 'The description for the api endpoints product.',
  },
  apiEndpointsProductIconAlt: {
    defaultMessage: 'API Endpoints Product Icon',
    id: 'c16qkFOm',
    description: 'The alt text for the API endpoints product icon.',
  },
  apiEndpointsProductHeader: {
    defaultMessage: 'API Endpoints',
    id: 'UhpELoF9',
    description: 'The header for the API endpoints product.',
  },
  apiEndpointsIntroCreditsTag: {
    defaultMessage: 'Get Your First 5 Hours of Transcription Free on SaladCloud',
    id: 'fTfwKbeR',
    description: 'The intro credits tag for the API endpoints.',
  },
  backToProjectsLinkText: {
    defaultMessage: 'Back to Projects',
    id: '+QYJQbda',
    description: 'The link text for the back button.',
  },
  containerGroupsProductButtonLabel: {
    defaultMessage: 'Create Container Group',
    id: '3Pr85B3e',
    description: 'The button label for the create container groups product button.',
  },
  containerGroupsProductDescription: {
    defaultMessage:
      'Custom containerized applications on Salad. Containers give you full control over your deployment, allowing deeper integrations with your existing applications and pipelines.',
    id: 'YAUyIznd',
    description: 'The description for the container groups product.',
  },
  containerGroupsProductHeader: {
    defaultMessage: 'Container Groups',
    id: 'D7xVjYL6',
    description: 'The header for the container groups product.',
  },
  containerGroupsProductIconAlt: {
    defaultMessage: 'containerGroups Product Icon',
    id: 'nOAjHo/b',
    description: 'The alt text for the container groups product icon.',
  },
  containerGroupsQuotaProductTag: {
    defaultMessage: 'Quota: 5 Containers, with 10 Replicas Each',
    id: 'o7isM6aK',
    description: 'The quota product tag for container groups.',
  },
  description: {
    defaultMessage:
      'Make a container group to manage and distribute HPC workloads, and rendering queues to thousands of 3D-accelerated GPUs.',
    id: 'RBt11IW/',
    description: 'The description about what selecting specific project product means.',
  },
  quickStartProductTag: {
    defaultMessage: 'QUICKSTART',
    id: 'xwzj67q4',
    description: 'The QUICKSTART product tag.',
  },
  templateProductTag: {
    defaultMessage: 'TEMPLATE',
    id: 'IOZTbm5e',
    description: 'The template product tag.',
  },
  title: {
    defaultMessage: 'Select Product',
    id: '5Hrxuluk',
    description: 'The title of the Select Product page.',
  },
})
