import { LoginProblemType, ResponseError } from '@saladtechnologies/openapi-cloud-portal-browser'
import { catchError, concat, delay, filter, from, mergeMap, of } from 'rxjs'
import { UserAccountsAPI, assertUnreachable } from '../apiMethods'
import { setAuthenticatedState } from '../features/authentication/authenticationSlice'
import { login } from '../features/login/loginSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { setProfile } from '../features/profile/profileSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import {
  getInvalidCredentialsContent,
  getUnauthenticatedRequestFailedContent,
} from '../notifications/clientToastNotificationContent'
import { organizationsPageRoutePath } from '../routes/routePaths'
import type { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

/**
 * An epic that logs the user in to their Salad Cloud account. If the request is successful, they are navigated to the
 * `/organizations` page.
 */
export const onLogin: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(login.match),
    mergeMap((action) =>
      concat(
        of(setRequestStatus({ request: 'login', status: 'pending' })),
        from(
          UserAccountsAPI.login({
            login: {
              email: action.payload.email,
              password: action.payload.password,
            },
          }),
        ).pipe(
          mergeMap(() =>
            from(UserAccountsAPI.getProfile()).pipe(
              mergeMap((profile) =>
                concat(
                  of(
                    setRequestStatus({ request: 'login', status: 'succeeded' }),
                    setProfile({ profile }),
                    setAuthenticatedState({ authenticated: true }),
                    navigateTo({ path: organizationsPageRoutePath }),
                  ),
                  of(setRequestStatus({ request: 'login', status: 'idle' })).pipe(delay(1)),
                ),
              ),
            ),
          ),
          catchError((error: unknown) => {
            return error instanceof ResponseError && error.response.status === 400
              ? from(error.response.json()).pipe(
                  mergeMap((errorResponse) => {
                    const errorType = errorResponse.type as LoginProblemType | null
                    if (errorType != null) {
                      switch (errorType) {
                        default:
                          assertUnreachable(errorType)
                      }
                    } else {
                      return concat(
                        of(
                          showToastNotification(getInvalidCredentialsContent()),
                          setRequestStatus({ request: 'login', status: 'failed' }),
                        ),
                        of(setRequestStatus({ request: 'login', status: 'idle' })).pipe(delay(1)),
                      )
                    }
                  }),
                )
              : concat(
                  of(
                    showToastNotification(getUnauthenticatedRequestFailedContent(intl)),
                    setRequestStatus({ request: 'login', status: 'failed' }),
                  ),
                  of(setRequestStatus({ request: 'login', status: 'idle' })).pipe(delay(1)),
                )
          }),
        ),
      ),
    ),
  )
