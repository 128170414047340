import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom'
import {
  AuthenticationPageContainer,
  ConnectedAccountSettingsPage,
  ConnectedApiKeyPage,
  ConnectedBillingPage,
  ConnectedChangeAPIKeyPage,
  ConnectedChangePasswordPage,
  ConnectedContainerGroupDetailsPage,
  ConnectedContainerGroupInstanceDetailsPage,
  ConnectedContainerGroupsPage,
  ConnectedCreateJobQueuePage,
  ConnectedCreateNewOrganizationPage,
  ConnectedCreateProjectPage,
  ConnectedEditContainerGroupPage,
  ConnectedEditJobQueuePage,
  ConnectedEditOrganizationPage,
  ConnectedEditProjectPage,
  ConnectedInferenceEndpointsMarketplacePage,
  ConnectedInviteTeamMemberPage,
  ConnectedJobQueueDetailsPage,
  ConnectedJobQueuesPage,
  ConnectedOrganizationsPage,
  ConnectedSelectProductPage,
  ConnectedTeamPage,
  ConnectedVerifyAccountPage,
  ConnectedWebhookSecretKeyPage,
  ForgotPasswordPageContainer,
  PageNotFound,
  ResetPasswordPageContainer,
} from '../pages'
import { ConnectedCreateContainerGroupPage } from '../pages/CreateContainerGroup'
import { ConnectedInferenceEndpointDetailsPage } from '../pages/InferenceEndpointDetails/ConnectedInferenceEndpointDetailsPage'
import { ConnectedNoProjectsAvailablePage } from '../pages/NoProjectsAvailable/ConnectedNoProjectsAvailable'
import { PrivateRoute } from './PrivateRoute'
import { PublicRoute } from './PublicRoute'
import {
  accountPageRoutePath,
  apiKeyPageRoutePath,
  authenticationPageRoutePath,
  changeAPIKeyPageRoutePath,
  changePasswordPageRoutePath,
  containerGroupDetailsPageRoutePath,
  containerGroupInstanceDetailsPageRoutePath,
  containersPageRoutePath,
  createContainerGroupPageRoutePath,
  createJobQueuePageRoutePath,
  createOrganizationPageRoutePath,
  createProjectPageRoutePath,
  editContainerGroupPageRoutePath,
  editJobQueuePageRoutePath,
  editOrganizationPageRoutePath,
  editProjectPageRoutePath,
  forgotPasswordPageRoutePath,
  inferenceEndpointDetailsPageRoutePath,
  inferenceEndpointsMarketplacePageRoutePath,
  inviteTeamMemberPageRoutePath,
  jobQueueDetailsPageRoutePath,
  jobQueuesPageRoutePath,
  noProjectsAvailablePageRoutePath,
  organizationBillingPageRoutePath,
  organizationsPageRoutePath,
  resetPasswordPageRoutePath,
  selectProductPageRoutePath,
  teamPageRoutePath,
  verifyAccountPageRoutePath,
  webhookSecretKeyRoutePath,
} from './routePaths'

export const routes: RouteObject[] = [
  {
    index: true,
    id: authenticationPageRoutePath,
    element: (
      <PublicRoute>
        <AuthenticationPageContainer />
      </PublicRoute>
    ),
    errorElement: <PageNotFound />,
  },
  {
    id: verifyAccountPageRoutePath,
    path: verifyAccountPageRoutePath,
    element: (
      <PublicRoute>
        <ConnectedVerifyAccountPage />
      </PublicRoute>
    ),
  },
  {
    id: forgotPasswordPageRoutePath,
    path: forgotPasswordPageRoutePath,
    element: (
      <PublicRoute>
        <ForgotPasswordPageContainer />
      </PublicRoute>
    ),
  },
  {
    id: resetPasswordPageRoutePath,
    path: resetPasswordPageRoutePath,
    element: (
      <PublicRoute>
        <ResetPasswordPageContainer />
      </PublicRoute>
    ),
  },
  {
    id: accountPageRoutePath,
    path: accountPageRoutePath,
    element: (
      <PrivateRoute>
        <ConnectedAccountSettingsPage />
      </PrivateRoute>
    ),
  },
  {
    id: changePasswordPageRoutePath,
    path: changePasswordPageRoutePath,
    element: (
      <PrivateRoute>
        <ConnectedChangePasswordPage />
      </PrivateRoute>
    ),
  },
  {
    id: apiKeyPageRoutePath,
    path: apiKeyPageRoutePath,
    element: (
      <PrivateRoute>
        <ConnectedApiKeyPage />
      </PrivateRoute>
    ),
  },
  {
    id: changeAPIKeyPageRoutePath,
    path: changeAPIKeyPageRoutePath,
    element: (
      <PrivateRoute>
        <ConnectedChangeAPIKeyPage />
      </PrivateRoute>
    ),
  },
  {
    id: organizationsPageRoutePath,
    path: organizationsPageRoutePath,
    element: (
      <PrivateRoute>
        <ConnectedOrganizationsPage />
      </PrivateRoute>
    ),
  },
  {
    id: createOrganizationPageRoutePath,
    path: createOrganizationPageRoutePath,
    element: (
      <PrivateRoute>
        <ConnectedCreateNewOrganizationPage />
      </PrivateRoute>
    ),
  },
  {
    id: editOrganizationPageRoutePath,
    path: editOrganizationPageRoutePath,
    element: (
      <PrivateRoute>
        <ConnectedEditOrganizationPage />
      </PrivateRoute>
    ),
  },
  {
    id: webhookSecretKeyRoutePath,
    path: webhookSecretKeyRoutePath,
    element: (
      <PrivateRoute>
        <ConnectedWebhookSecretKeyPage />
      </PrivateRoute>
    ),
  },
  {
    id: teamPageRoutePath,
    path: teamPageRoutePath,
    element: (
      <PrivateRoute>
        <ConnectedTeamPage />
      </PrivateRoute>
    ),
  },
  {
    id: inviteTeamMemberPageRoutePath,
    path: inviteTeamMemberPageRoutePath,
    element: (
      <PrivateRoute>
        <ConnectedInviteTeamMemberPage />
      </PrivateRoute>
    ),
  },
  {
    id: noProjectsAvailablePageRoutePath,
    path: noProjectsAvailablePageRoutePath,
    element: (
      <PrivateRoute>
        <ConnectedNoProjectsAvailablePage />
      </PrivateRoute>
    ),
  },
  {
    id: createProjectPageRoutePath,
    path: createProjectPageRoutePath,
    element: (
      <PrivateRoute>
        <ConnectedCreateProjectPage />
      </PrivateRoute>
    ),
  },
  {
    id: editProjectPageRoutePath,
    path: editProjectPageRoutePath,
    element: (
      <PrivateRoute>
        <ConnectedEditProjectPage />
      </PrivateRoute>
    ),
  },
  {
    id: containersPageRoutePath,
    path: containersPageRoutePath,
    element: (
      <PrivateRoute>
        <ConnectedContainerGroupsPage />
      </PrivateRoute>
    ),
  },
  {
    id: organizationBillingPageRoutePath,
    path: organizationBillingPageRoutePath,
    element: (
      <PrivateRoute>
        <ConnectedBillingPage />
      </PrivateRoute>
    ),
  },
  {
    id: createContainerGroupPageRoutePath,
    path: createContainerGroupPageRoutePath,
    element: (
      <PrivateRoute>
        <ConnectedCreateContainerGroupPage />
      </PrivateRoute>
    ),
  },
  {
    id: containerGroupDetailsPageRoutePath,
    path: containerGroupDetailsPageRoutePath,
    element: (
      <PrivateRoute>
        <ConnectedContainerGroupDetailsPage />
      </PrivateRoute>
    ),
  },
  {
    id: editContainerGroupPageRoutePath,
    path: editContainerGroupPageRoutePath,
    element: (
      <PrivateRoute>
        <ConnectedEditContainerGroupPage />
      </PrivateRoute>
    ),
  },
  {
    id: containerGroupInstanceDetailsPageRoutePath,
    path: containerGroupInstanceDetailsPageRoutePath,
    element: (
      <PrivateRoute>
        <ConnectedContainerGroupInstanceDetailsPage />
      </PrivateRoute>
    ),
  },
  {
    id: selectProductPageRoutePath,
    path: selectProductPageRoutePath,
    element: (
      <PrivateRoute>
        <ConnectedSelectProductPage />
      </PrivateRoute>
    ),
  },
  {
    id: inferenceEndpointsMarketplacePageRoutePath,
    path: inferenceEndpointsMarketplacePageRoutePath,
    element: (
      <PrivateRoute>
        <ConnectedInferenceEndpointsMarketplacePage />
      </PrivateRoute>
    ),
  },
  {
    id: inferenceEndpointDetailsPageRoutePath,
    path: inferenceEndpointDetailsPageRoutePath,
    element: (
      <PrivateRoute>
        <ConnectedInferenceEndpointDetailsPage />
      </PrivateRoute>
    ),
  },
  {
    id: jobQueuesPageRoutePath,
    path: jobQueuesPageRoutePath,
    element: (
      <PrivateRoute>
        <ConnectedJobQueuesPage />
      </PrivateRoute>
    ),
  },
  {
    id: editJobQueuePageRoutePath,
    path: editJobQueuePageRoutePath,
    element: (
      <PrivateRoute>
        <ConnectedEditJobQueuePage />
      </PrivateRoute>
    ),
  },
  {
    id: createJobQueuePageRoutePath,
    path: createJobQueuePageRoutePath,
    element: (
      <PrivateRoute>
        <ConnectedCreateJobQueuePage />
      </PrivateRoute>
    ),
  },
  {
    id: jobQueueDetailsPageRoutePath,
    path: jobQueueDetailsPageRoutePath,
    element: (
      <PrivateRoute>
        <ConnectedJobQueueDetailsPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/organizations/:organizationName/projects',
    element: (
      <PrivateRoute>
        <Navigate replace to="/organizations" />
      </PrivateRoute>
    ),
  },
  {
    path: '/organizations/:organizationName',
    element: (
      <PrivateRoute>
        <Navigate replace to="/organizations" />
      </PrivateRoute>
    ),
  },
]

export const router = createBrowserRouter(routes)
