import { catchError, concat, delay, filter, forkJoin, from, mergeMap, of } from 'rxjs'
import { InvitationsAPI, OrganizationsAPI, ProjectsAPI } from '../apiMethods'
import { getTeamMemberPageData, inviteTeamMember } from '../features/inviteTeamMember/inviteTeamMemberSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { organizationAdded } from '../features/organizations/organizationsSlice'
import { projectsAddedToOrganization } from '../features/projects/projectsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import {
  getGeneralErrorInvitingTeamMemberContent,
  getInvitedTeamMemberSucceededContent,
} from '../notifications/clientToastNotificationContent/team'
import type { AppEpic } from '../store'

export const onGetInviteTeamMemberPageData: AppEpic = (action$, _state$) =>
  action$.pipe(
    filter(getTeamMemberPageData.match),
    mergeMap(({ payload: { organizationName } }) =>
      concat(
        of(setRequestStatus({ request: 'getInviteTeamMemberPageData', status: 'pending' })),
        forkJoin([
          OrganizationsAPI.getOrganization({
            organizationName: organizationName,
          }),
          ProjectsAPI.listProjects({
            organizationName,
          }),
        ]).pipe(
          mergeMap(([organizationResponse, projectListResponse]) => {
            return concat(
              of(
                setRequestStatus({ request: 'getInviteTeamMemberPageData', status: 'succeeded' }),
                organizationAdded(organizationResponse),
                projectsAddedToOrganization({ organizationName, projects: projectListResponse.items }),
              ),
              of(setRequestStatus({ request: 'getInviteTeamMemberPageData', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError(() =>
            concat(
              of(setRequestStatus({ request: 'getInviteTeamMemberPageData', status: 'failed' })),
              of(setRequestStatus({ request: 'getInviteTeamMemberPageData', status: 'idle' })).pipe(delay(1)),
            ),
          ),
        ),
      ),
    ),
  )

export const onInviteTeamMember: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(inviteTeamMember.match),
    mergeMap((action) =>
      concat(
        of(setRequestStatus({ request: 'inviteTeamMember', status: 'pending' })),
        from(
          InvitationsAPI.createInvitation({
            createInvitation: {
              email: action.payload.email,
            },
            organizationName: action.payload.organizationName,
          }),
        ).pipe(
          mergeMap(() => {
            return concat(
              of(setRequestStatus({ request: 'inviteTeamMember', status: 'succeeded' })),
              of(setRequestStatus({ request: 'inviteTeamMember', status: 'idle' })).pipe(delay(1)),
              of(
                showToastNotification(
                  getInvitedTeamMemberSucceededContent(intl, action.payload.email, action.payload.organizationName),
                ),
              ),
            )
          }),
          catchError(() => {
            return concat(
              of(setRequestStatus({ request: 'inviteTeamMember', status: 'failed' })),
              of(setRequestStatus({ request: 'inviteTeamMember', status: 'idle' })).pipe(delay(1)),
              of(showToastNotification(getGeneralErrorInvitingTeamMemberContent(intl, action.payload.email))),
            )
          }),
        ),
      ),
    ),
  )
