import {
  BillingApi,
  Configuration,
  ContainerGroupsApi,
  HelpScoutIntegrationApi,
  InferenceEndpointsApi,
  InvitationsApi,
  MembersApi,
  OrganizationDataApi,
  OrganizationOptionsApi,
  OrganizationsApi,
  ProjectsApi,
  QueuesApi,
  QuotasApi,
  UserAccountsApi,
  WebhookSecretKeyApi,
  WorkloadErrorsApi,
  NovuIntegrationApi,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import { API_URL } from './config'

const baseConfig = new Configuration({
  basePath: API_URL,
  credentials: 'include',
})

export const BillingAPI = new BillingApi(baseConfig)

export const ContainerGroupsAPI = new ContainerGroupsApi(baseConfig)

export const HelpScoutIntegrationAPI = new HelpScoutIntegrationApi(baseConfig)

export const InferenceEndpointsAPI = new InferenceEndpointsApi(baseConfig)

export const InvitationsAPI = new InvitationsApi(baseConfig)

export const MembersAPI = new MembersApi(baseConfig)

export const OrganizationDataAPI = new OrganizationDataApi(baseConfig)

export const OrganizationOptionsAPI = new OrganizationOptionsApi(baseConfig)

export const OrganizationsAPI = new OrganizationsApi(baseConfig)

export const ProjectsAPI = new ProjectsApi(baseConfig)

export const QueuesAPI = new QueuesApi(baseConfig)

export const QuotasAPI = new QuotasApi(baseConfig)

export const UserAccountsAPI = new UserAccountsApi(baseConfig)

export const WebhookSecretKeyAPI = new WebhookSecretKeyApi(baseConfig)

export const WorkloadErrorsAPI = new WorkloadErrorsApi(baseConfig)

export const NovuIntegrationAPI = new NovuIntegrationApi(baseConfig)

export function assertUnreachable(_x: never): never {
  throw new Error("Didn't expect to get here")
}
