import {
  ConfirmRegistrationProblemType,
  RegisterProblemType,
  ResendRegistrationEmailProblemType,
  ResponseError,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import { catchError, concat, delay, filter, from, mergeMap, of } from 'rxjs'
import { UserAccountsAPI, assertUnreachable } from '../apiMethods'
import { setAuthenticationPage } from '../features/authentication/authenticationSlice'
import { AuthenticationPage } from '../features/authentication/models/AuthenticationPage'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { registerAccount, resendRegistrationEmail } from '../features/registration/registrationSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import { confirmRegistration, setVerifyAccountResult } from '../features/verifyAccount/verifyAccountSlice'
import {
  getSuccessfullySentEmailToastContent,
  getUnauthenticatedRequestFailedContent,
} from '../notifications/clientToastNotificationContent'
import { getEmailNotSentContent } from '../notifications/clientToastNotificationContent/registration'
import type { AppEpic } from '../store'

/**
 * An epic that verifies the user's account based on the token provided. Based on the result of the verification call,
 * the user is presented with the corresponding UI to guide the user on next steps.
 */
export const onConfirmRegistration: AppEpic = (action$) =>
  action$.pipe(
    filter(confirmRegistration.match),
    mergeMap((action) => {
      return concat(
        of(
          setVerifyAccountResult({ result: undefined }),
          setRequestStatus({ request: 'confirmRegistration', status: 'pending' }),
        ),
        from(
          UserAccountsAPI.confirmRegistration({
            confirmRegistration: {
              token: action.payload.token,
            },
          }),
        ).pipe(
          mergeMap(() =>
            concat(
              of(
                setVerifyAccountResult({ result: 'success' }),
                setRequestStatus({ request: 'confirmRegistration', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'confirmRegistration', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          catchError((error: unknown) => {
            return error instanceof ResponseError && error.response.status === 400
              ? from(error.response.json()).pipe(
                  mergeMap((errorResponse) => {
                    const errorType = errorResponse.type as ConfirmRegistrationProblemType | null
                    if (errorType != null) {
                      switch (errorType) {
                        default:
                          assertUnreachable(errorType)
                      }
                    } else {
                      return concat(
                        of(
                          setVerifyAccountResult({ result: 'expired' }),
                          setRequestStatus({ request: 'confirmRegistration', status: 'failed' }),
                        ),
                        of(setRequestStatus({ request: 'confirmRegistration', status: 'idle' })).pipe(delay(1)),
                      )
                    }
                  }),
                )
              : concat(
                  of(
                    setVerifyAccountResult({ result: 'error' }),
                    setRequestStatus({ request: 'confirmRegistration', status: 'failed' }),
                  ),
                  of(setRequestStatus({ request: 'confirmRegistration', status: 'idle' })).pipe(delay(1)),
                )
          }),
        ),
      )
    }),
  )

/**
 * An epic that starts the registration process for the user's Salad Cloud account. If the request is succesful, the
 * user will recieve an email with a verification link and be presented with UI that notifies them to check their
 * email.
 */
export const onRegisterAccount: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(registerAccount.match),
    mergeMap((action) => {
      return concat(
        of(setRequestStatus({ request: 'registerAccount', status: 'pending' })),
        from(
          UserAccountsAPI.register({
            register: {
              email: action.payload.email,
              password: action.payload.password,
            },
          }),
        ).pipe(
          mergeMap(() =>
            concat(
              of(
                setRequestStatus({ request: 'registerAccount', status: 'succeeded' }),
                setAuthenticationPage({ page: AuthenticationPage.VerificationEmailSent }),
              ),
              of(setRequestStatus({ request: 'registerAccount', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          catchError((error: unknown) => {
            const requestFailedToastNotificationContent = getUnauthenticatedRequestFailedContent(intl)
            return error instanceof ResponseError && error.response.status === 400
              ? from(error.response.json()).pipe(
                  mergeMap((errorResponse) => {
                    const errorType = errorResponse.type as RegisterProblemType | null
                    if (errorType != null) {
                      switch (errorType) {
                        default:
                          assertUnreachable(errorType)
                      }
                    } else {
                      return concat(
                        of(
                          showToastNotification(requestFailedToastNotificationContent),
                          setRequestStatus({ request: 'registerAccount', status: 'failed' }),
                        ),
                        of(setRequestStatus({ request: 'registerAccount', status: 'idle' })).pipe(delay(1)),
                      )
                    }
                  }),
                )
              : concat(
                  of(
                    showToastNotification(requestFailedToastNotificationContent),
                    setRequestStatus({ request: 'registerAccount', status: 'failed' }),
                  ),
                  of(setRequestStatus({ request: 'registerAccount', status: 'idle' })).pipe(delay(1)),
                )
          }),
        ),
      )
    }),
  )

/**
 * An epic that resends the registration email to the user. If the request is successful, they will receive a `Toast
 * Notification` guiding them to check their email to complete registration.
 */
export const onResendRegistrationEmail: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(resendRegistrationEmail.match),
    mergeMap((action) => {
      return concat(
        of(setRequestStatus({ request: 'resendRegistrationEmail', status: 'pending' })),
        from(
          UserAccountsAPI.resendRegistrationEmail({
            resendRegistrationEmail: {
              email: action.payload.email,
            },
          }),
        ).pipe(
          mergeMap(() =>
            concat(
              of(
                showToastNotification(getSuccessfullySentEmailToastContent()),
                setRequestStatus({ request: 'resendRegistrationEmail', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'resendRegistrationEmail', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          catchError((error: unknown) => {
            const requestFailedToastNotificationContent = getUnauthenticatedRequestFailedContent(intl)
            return error instanceof ResponseError && error.response.status === 400
              ? from(error.response.json()).pipe(
                  mergeMap((errorResponse) => {
                    const errorType = errorResponse.type as ResendRegistrationEmailProblemType | null
                    if (errorType != null) {
                      switch (errorType) {
                        case ResendRegistrationEmailProblemType.EmailNotSent:
                          return concat(
                            of(
                              showToastNotification(getEmailNotSentContent(intl, action.payload.email)),
                              setRequestStatus({ request: 'resendRegistrationEmail', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'resendRegistrationEmail', status: 'idle' })).pipe(delay(1)),
                          )
                        default:
                          assertUnreachable(errorType)
                      }
                    } else {
                      return concat(
                        of(
                          showToastNotification(requestFailedToastNotificationContent),
                          setRequestStatus({ request: 'resendRegistrationEmail', status: 'failed' }),
                        ),
                        of(setRequestStatus({ request: 'resendRegistrationEmail', status: 'idle' })).pipe(delay(1)),
                      )
                    }
                  }),
                )
              : concat(
                  of(
                    showToastNotification(requestFailedToastNotificationContent),
                    setRequestStatus({ request: 'resendRegistrationEmail', status: 'failed' }),
                  ),
                  of(setRequestStatus({ request: 'resendRegistrationEmail', status: 'idle' })).pipe(delay(1)),
                )
          }),
        ),
      )
    }),
  )
