import { defineMessages } from 'react-intl'

export const PresetContainerMessages = defineMessages({
  allWorkloadsOption: {
    defaultMessage: 'All Workloads',
    id: 'XLSPXmwk',
    description: 'The option for all workloads.',
  },
  backLinkText: {
    defaultMessage: 'Back',
    id: 'DIEhxLoS',
    description: 'The text for the back link.',
  },
  customContainerGroupCardDescription: {
    defaultMessage: 'Fully customize the container group deployment. {learn_more}',
    id: 'S+9rTNYq',
    description: 'The description for the custom container group card.',
  },
  customContainerGroupCardTitle: {
    defaultMessage: 'Custom Container Group',
    id: '/gpNcLog',
    description: 'The title for the custom container group card.',
  },
  dockerDescription: {
    defaultMessage: 'Easily convert your docker run command into a container group deployment.',
    id: 'lVoUEs78',
    description: 'The description for the Docker card.',
  },
  dockerTitle: {
    defaultMessage: 'Docker Run',
    id: 'MsFRRDlv',
    description: 'The title for the Docker card.',
  },
  featuredText: {
    defaultMessage: 'Featured',
    id: '0M6Qbv9u',
    description: 'The text for the featured section.',
  },
  getStartedButtonLabel: {
    defaultMessage: 'Get Started',
    id: '0mRJJZJm',
    description: 'The label for the get started button.',
  },
  imageGenWorkloadOption: {
    defaultMessage: 'Image Generation',
    id: 'lcwpfENy',
    description: 'The option for Image Generation workload type.',
  },
  learnMoreText: {
    defaultMessage: 'Learn More',
    id: 'ySYJyzac',
    description: 'The text for the learn more link.',
  },
  llmWorkloadOption: {
    defaultMessage: 'LLM',
    id: 'S3iREb9e',
    description: 'The option for LLM workload type.',
  },
  objectDetectionWorkloadOption: {
    defaultMessage: 'Object Detection',
    id: 'DgLW9FdN',
    description: 'The option for Object Detection workload type.',
  },
  presetContainerSubtitle: {
    defaultMessage:
      'Deploy your own custom containerized applications on Salad. Containers give you full control over your deployment, allowing deeper integrations with your existing applications and pipelines.',
    id: 'F2Y4Wrw3',
    description: 'The subtitle for the preset container page.',
  },
  presetContainerTitle: {
    defaultMessage: 'Create a New Container Group',
    id: 'TEB9Yr3w',
    description: 'The title for the preset container page.',
  },
  recipesDreamshaperDescription: {
    defaultMessage: 'Deploy Dreamshaper 8 as an API with ComfyUI. {learn_more}',
    id: 'hP6HmXEH',
    description: 'The description for the Dreamshaper 8 card.',
  },
  recipesDreamshaperTitle: {
    defaultMessage: 'Dreamshaper 8 - ComfyUI (API)',
    id: '4n/Ox7qF',
    description: 'The title for the Dreamshaper 8 card.',
  },
  recipesFluxSchnellDescription: {
    defaultMessage: 'Deploy FLUX.1-Schnell (FP8) as an API with ComfyUI. {learn_more}',
    id: 'gqSHi//c',
    description: 'The description for the FLUX.1-Schnell card.',
  },
  recipesFluxSchnellTitle: {
    defaultMessage: 'FLUX.1-Schnell (FP8) - ComfyUI (API)',
    id: 'HCiCvy3b',
    description: 'The title for the FLUX.1-Schnell card.',
  },
  recipesOllamaLlama31Description: {
    defaultMessage: 'Deploy Ollama Llama 3.1 as an API with Ollama. {learn_more}',
    id: 'vLK+0Hat',
    description: 'The description for the Ollama Llama 3.1 card.',
  },
  recipesOllamaLlama31Title: {
    defaultMessage: 'Ollama Llama 3.1',
    id: 'TBY9M9MK',
    description: 'The title for the Ollama Llama 3.1 card.',
  },
  recipesSDXLRefinerDescription: {
    defaultMessage: 'Deploy Stable Diffusion XL with the refiner model as an API with ComfyUI. {learn_more}',
    id: '7kzeaWoS',
    description: 'The description for the SDXL with Refiner card.',
  },
  recipesSDXLRefinerTitle: {
    defaultMessage: 'SDXL with Refiner - ComfyUI (API)',
    id: 'Pxuix3DU',
    description: 'The title for the SDXL with Refiner card.',
  },
  recipesTgiMistral7bInstructDescription: {
    defaultMessage:
      'Deploy Mistral 7B Instruct with Text Generation Inference. Set your Hugging Face token in environment variables. {learn_more}',
    id: 'zV5Mbj9O',
    description: 'The description for the TGI Mistral 7B Instruct card.',
  },
  recipesTgiMistral7bInstructTitle: {
    defaultMessage: 'TGI Mistral 7B Instruct',
    id: 'Y5Pai/JQ',
    description: 'The title for the TGI Mistral 7B Instruct card.',
  },
  recipesTitle: {
    defaultMessage: 'Recipes',
    id: 'ObNNMk++',
    description: 'The title for the recipes section.',
  },
  recipesYolov8MDescription: {
    defaultMessage: 'Deploy YOLOv8-M model for object detection via API. {learn_more}',
    id: 'tcNzV2My',
    description: 'The description for the YOLOv8-M card.',
  },
  recipesYolov8MTitle: {
    defaultMessage: 'YOLOv8-M',
    id: '4RqVyNtS',
    description: 'The title for the YOLOv8-M card.',
  },
  selectWorkloadTypeLabel: {
    defaultMessage: 'Select Workload Type',
    id: 'I/5tMWBM',
    description: 'Label for the workload type selection dropdown.',
  },
  startingPointText: {
    defaultMessage: 'Starting Point',
    id: 'ni2tr/J2',
    description: 'The text for the starting point section.',
  },
  transcriptionWorkloadOption: {
    defaultMessage: 'Transcription',
    id: 'XmLLgXi1',
    description: 'The option for Transcription workload type.',
  },
  ubuntuCardDescription: {
    defaultMessage: 'Deploy a basic Ubuntu environment with terminal access. {learn_more}',
    id: 'RWVZ5+8r',
    description: 'The description for the Ubuntu card.',
  },
  ubuntuCardTitle: {
    defaultMessage: 'Ubuntu',
    id: 'oPlPEPND',
    description: 'The title for the Ubuntu card.',
  },
})
