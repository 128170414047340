import { defineMessages } from 'react-intl'

export const ExhaustedFreeTrialModalMessages = defineMessages({
  title: {
    defaultMessage: 'You’ve Exhausted Your Free Trial Credits',
    id: 'O0fnFyBB',
    description: 'The title message displayed when the user has exhausted their free trial credits',
  },
  description: {
    defaultMessage:
      'You’ve exhausted your free trial credits! To run more workloads, you need to add payment information to your organization.',
    id: 'uLKLjTfd',
    description: 'The main message displayed informing the user about exhausting their free trial credits',
  },
  additionalInfo: {
    defaultMessage:
      'Click the button below to visit your billing menu, where you can add a valid payment method and continue running Transcription API jobs!',
    id: 'UA91XaLO',
    description: 'The additional information message directing the user to the billing menu',
  },
  backButtonLabel: {
    defaultMessage: 'Back',
    id: 'tavrxoGl',
    description: 'The label for the back button',
  },
  viewBillingInfoButtonLabel: {
    defaultMessage: 'View Billing Information',
    id: 'qjWTjsEK',
    description: 'The label for the button that redirects the user to view billing information',
  },
})
