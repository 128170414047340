import { defineMessages } from 'react-intl'

export const SaladVerifiedMessages = defineMessages({
  SaladVerifiedLabel: {
    defaultMessage: 'Salad Verified',
    id: '7dqBghP4',
    description: "The label for our Salad Logo that's shown on inference endpoint cards.",
  },
  SaladVerifiedAltTag: {
    defaultMessage: 'Salad Logo',
    id: 'UxrvimUJ',
    description: "The alt tag for our Salad Logo that's shown on inference endpoint cards.",
  },
})
